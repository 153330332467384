<template>
  <v-row class="evi-auth">
    <vue-particles
        class="evi-auth__particles"
        color="#A1E9B7"
        linesColor="#A1E9B7"
        :clickEffect="false"
        hoverMode="repulse"
        :particlesNumber="80"
    />
    <v-col cols="6">
      <div class="evi-auth__logo">
        <img width="69" class="mr-2" :src="require('@/assets/logo.svg')" alt="">
        <span class="logo-text">EviSalesCRM</span>
      </div>
    </v-col>
    <v-col
        cols="4"
        class="d-flex justify-center align-center"
    >
      <div class="evi-auth__window">
        <h3>
          Восстановление пароля
        </h3>
        <p>
          Введите почту от учётной записи пользователя EviSales CRM
        </p>
        <v-text-field
            v-model="user.email"
            label="Email"
            placeholder="Введите Email"
            class="evi-text-field"
            color="#44D370"
            :rules="emailRules"
            @keydown.enter.exact.prevent="restorePassword"
        />
        <v-btn class="evi-button-green evi-button-green--fill" height="40" @click="restorePassword()"
               :disabled="loginButtonDisabled">
          Отправить
        </v-btn>
        <a class="password-repair mt-5" @click="$router.push({path: 'login'})">Вернуться на страницу входа</a>
      </div>
      <v-dialog
          v-model="mailSentModal"
          max-width="612px"
      >
        <v-card class="modal-create">
          <v-card-title class="d-flex justify-center">
            <span class="text-h5 text-center mb-11">Восстановление пароля</span>
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            Ссылка со сбросом пароля отправлена на почту <span class="font-weight-bold ml-1">{{ user.email }}</span>
          </v-card-text>
          <v-card-actions class="modal-create__actions">
            <v-btn class="evi-button-green evi-button-green--fill"
                   @click="complete">
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Password-Change",
  data: () => ({
    user: {
      email: '',
    },
    show: false,
    mailSentModal: false,
  }),
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'Введите почту',
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
      ];
    },
    loginButtonDisabled() {
      return !this.user.email;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    restorePassword() {
      this.$store.dispatch('user/restorePassword', {
        email: this.user.email.toLowerCase(),
      }).then(() => {
        this.mailSentModal = true;
      });
    },
    complete() {
      this.mailSentModal = true;
      this.$router.push({path: 'login'});
    }
  }
}
</script>

<style lang="scss" scoped>
.evi-auth {
  h3 {
    margin-bottom: 20px;
  }
}
</style>
